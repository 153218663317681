import axios from "axios";
import getDecryptedAccessToken from "../../../auth/AccessToken";
import config from "../../../config";

const accessToken = getDecryptedAccessToken();

export const postCrearPoligonoRestriccionVehiculoCliente = async (selectedVehicles, polygonCoordinates, navigate) => {
    const apiUrl = `${config.apiBaseUrl}/CrearPoligonoRestriccionVehiculoCliente/`;
    const postData = {
        poligono: JSON.stringify(polygonCoordinates),
        vehiculos: selectedVehicles.map(vehicle => parseInt(vehicle.id, 10))
    };

    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
        console.error('No se encontró un token de acceso en localStorage');
        return;
    }

    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };

    try {
        const response = await axios.post(apiUrl, postData, { headers });
        if (response.status === 200) {
            window.location.reload();
            return true;
        } else if (response.status === 401) {
            console.error('Token de acceso inválido o expirado');
            navigate(`/login`);
            throw new Error('Token de acceso inválido o expirado');
        } else {
            console.error('Error en la respuesta del servidor:', response.data.message);
            throw new Error(response.data.message);
        }
    } catch (error) {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
        throw error; // Asegúrate de lanzar el error para que se capture en `handleSubmitForm`
    }
};
