import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polygon, useMap, Tooltip, FeatureGroup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import pejae_icon from '../../../assets/img/peaje.png'
import './map.css';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import {ReactComponent as MarkerIcon} from '../../../assets/img/marker-svgrepo-com.svg';
import {ReactComponent as ArrowsIcon} from '../../../assets/img/arrows-out-cardinal-bold-svgrepo-com.svg';
import ReactDOMServer from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import axios from 'axios';
import getDecryptedAccessToken from '../../../auth/AccessToken.js';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';

function createCustomIcon(color, markerSelected) {
  const CustomIcon = () => (
    <div style={{ position: 'relative', width: '45px', height: '51px' }}>
      {markerSelected && (
        <div style={{ position: 'absolute', top: '34%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1' }}>
          <ArrowsIcon fill={color} stroke='black' height='75' width='75' />
        </div>
      )}
      <MarkerIcon fill={color} stroke='black' height='45' width='45' style={{ position: 'relative', zIndex: '2' }} />
    </div>
  );

  return L.divIcon({
    iconSize: [50, 56],
    iconAnchor: [20, 38],
    popupAnchor: [3, -30],
    html: ReactDOMServer.renderToString(<CustomIcon />),
    className: 'dummy'
  });
}

function Map({ coordinates, isValidCoordinates, selectedPolygon }) {
  const map = useMap();

  // Si hay un polígono seleccionado, centramos el mapa en sus límites
  if (selectedPolygon && selectedPolygon.length > 0) {
    const bounds = L.latLngBounds(selectedPolygon);
    map.fitBounds(bounds, { maxZoom: 12 }); // Ajusta el nivel de zoom si es necesario
  } else if (isValidCoordinates && coordinates.length >= 2) {
    map.fitBounds(coordinates);
  } else if (isValidCoordinates && coordinates.length > 0) {
    const [lat, lng] = coordinates[0];
    map.setView([lat, lng], 16);
  }

  return null;
}

const ConfirmDeleteModal = ({ isOpen, toggleModal, poligonoId, setUploading, deletePoligonoRestriccionVehiculoCliente, navigate }) => {

  const handleDelete = async () => {
    try {
      toggleModal();
      setUploading(true);
      await deletePoligonoRestriccionVehiculoCliente(poligonoId, navigate);
    } catch (error) {
      console.error('Error al eliminar el polígono:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Confirmar Eliminación</ModalHeader>
      <ModalBody>
        ¿Está seguro de que desea eliminar este polígono? Esta acción no se puede deshacer.
      </ModalBody>
      <div className="d-flex justify-content-between" style={{ marginTop: '10px' }}>
        <Button color="secondary" onClick={toggleModal}>Cancelar</Button>{' '}
        <Button color="danger" onClick={handleDelete}>Eliminar</Button>
      </div>
    </Modal>
  );
}

const ConfirmCreateModal = ({ showModal, toggleModal, setSelectedVehicles, handleConfirmPolygon, vehiculos }) => {
  const [selectedVehicles, updateSelectedVehicles] = useState([]);

  // Manejar cambios en la selección de vehículos
  const handleVehicleSelection = (e) => {
    const selectedOptions = [...e.target.selectedOptions].map(option => ({
      id: option.value,
      placa: option.label,
    }));

    setSelectedVehicles(selectedOptions);
    updateSelectedVehicles(selectedOptions);
  };

  return (
    <Modal isOpen={showModal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Crear Polígono</ModalHeader>
      <ModalBody>
        <FormGroup>
        <Label for="vehiculosSelect">Elige los vehículos que no podrán asignarse a las direcciones dentro del polígono seleccionado:</Label>
          <Input
            type="select"
            name="vehiculos"
            id="vehiculosSelect"
            multiple
            onChange={handleVehicleSelection}
          >
            {vehiculos.map(vehiculo => (
              <option key={vehiculo.vehiculo_id} value={vehiculo.vehiculo_id}>
                {vehiculo.placa}
              </option>
            ))}
          </Input>
        </FormGroup>
      </ModalBody>
      <div className="d-flex justify-content-between" style={{ marginTop: '10px' }}>
        <Button color="secondary" onClick={toggleModal}>
          Cancelar
        </Button>{' '}
        <Button
          color="success"
          onClick={handleConfirmPolygon}
          disabled={selectedVehicles.length === 0}
        >
          Confirmar
        </Button>
      </div>
    </Modal>
  );
}

const ConfirmEditModal = ({ showModal, toggleModal, setSelectedVehicles, handleConfirmEditPolygon, vehiculos, actualVehicles }) => {
  const [selectedVehicles, updateSelectedVehicles] = useState([]);

  // Este efecto se asegura de que setSelectedVehicles se actualice con actualVehicles cuando se abra el modal
  useEffect(() => {
    if (showModal && actualVehicles.length > 0) {
      const initialSelectedVehicles = vehiculos
        .filter(vehiculo => actualVehicles.includes(vehiculo.vehiculo_id))
        .map(vehiculo => ({
          id: vehiculo.vehiculo_id,
          placa: vehiculo.placa
        }));

      setSelectedVehicles(initialSelectedVehicles);
      updateSelectedVehicles(initialSelectedVehicles);
    }
  }, [showModal, actualVehicles, vehiculos, setSelectedVehicles]);

  // Manejar cambios en la selección de vehículos
  const handleVehicleSelection = (e) => {
    const selectedOptions = [...e.target.selectedOptions].map(option => ({
      id: option.value,
      placa: option.label,
    }));

    setSelectedVehicles(selectedOptions);
    updateSelectedVehicles(selectedOptions);
  };

  return (
    <Modal isOpen={showModal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Editar Polígono</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="vehiculosSelect">Elige los vehículos que no podrán asignarse a las direcciones dentro del polígono seleccionado:</Label>
          <Input
            type="select"
            name="vehiculos"
            id="vehiculosSelect"
            multiple
            onChange={handleVehicleSelection}
            // Preseleccionar las opciones que están en actualVehicles
            defaultValue={actualVehicles}
          >
            {vehiculos.map(vehiculo => (
              <option key={vehiculo.vehiculo_id} value={vehiculo.vehiculo_id}>
                {vehiculo.placa}
              </option>
            ))}
          </Input>
        </FormGroup>
      </ModalBody>
      <div className="d-flex justify-content-between" style={{ marginTop: '10px' }}>
        <Button color="secondary" onClick={toggleModal}>
          Cancelar
        </Button>{' '}
        <Button
          color="success"
          onClick={handleConfirmEditPolygon}
          disabled={selectedVehicles.length === 0}
        >
          Confirmar
        </Button>
      </div>
    </Modal>
  );
}

function LeafletMapCustomers({ clientes, selectedCustomers, setSelectedCustomers, direccionesCliente, setSelectedAccuracys, selectedAccuracys, setSelectedPuntosIds,
  selectedPuntosIds, showButtons, setShowButtons, markerInitialPositions, setMarkerInitialPositions, markerPositions, setMarkerPositions, showPuntosNuevos,
  setShowPuntosNuevos, uploading, setUploading, numPages, actualPage, setActualPage, filtroNombre, bounds, postCrearPoligonoRestriccionVehiculoCliente, vehiculos,
  poligonos, deletePoligonoRestriccionVehiculoCliente, putEditarPoligonoRestriccionVehiculoCliente, isPolygonMode, setIsPolygonMode }) {
  const [puntosGeograficosToUpdate, setPuntosGeograficosToUpdate] = useState([]);
  const markerRef = useRef();
  const featureGroupRef = useRef(null);
  const navigate = useNavigate();
  const accessToken = getDecryptedAccessToken();
  const [newPolygon, setNewPolygon] = useState([]);
  const [direccionesClienteDentroPoligono, setDireccionesClienteDentroPoligono] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedClientes, setSelectedClientes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const direccionesClienteRef = useRef(direccionesCliente);
  const [selectedPolygonId, setSelectedPolygonId] = useState(null);
  const [selectedPolygonVehicles, setSelectedPolygonVehicles] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const polygonLayerRef = useRef(null);
  const [selectedPolygon, setSelectedPolygon] = useState([]);
  const [showPolygonModeMessage, setShowPolygonModeMessage] = useState(false);

  let filteredClientes = showPuntosNuevos
  ? clientes.filter(item => item.cliente_nuevo)
  : clientes;

  if (filtroNombre) {
    filteredClientes = filteredClientes
      .filter(item =>
        item.nombre.toLowerCase().includes(filtroNombre.toLowerCase()) ||
        item.cliente_id_externo.toLowerCase().includes(filtroNombre.toLowerCase())
      )
      .slice(0, 100);
  } else {
    filteredClientes = filteredClientes.slice(
      actualPage * 100,
      (actualPage + 1) * 100
    );
  };

  const clienteIdsSet = new Set(filteredClientes.map(cliente => cliente.cliente_id));

  let filteredData = selectedPuntosIds.size > 0
    ? direccionesCliente.filter(item => selectedPuntosIds.has(item.punto_id))
    : direccionesCliente;

  filteredData = clienteIdsSet
    ? filteredData.filter(item => clienteIdsSet.has(item.cliente_id))
    : filteredData;

  filteredData = showPuntosNuevos
    ? filteredData.filter(item => item.cliente_nuevo)
    : filteredData;

  filteredData = selectedCustomers.size > 0
    ? filteredData.filter(item => selectedCustomers.has(item.cliente_id))
    : filteredData;

  filteredData.forEach((item) => {
    if (item.accuracy === '3') {
      item.color = 'green';
      item.accuracy_name = 'Ubicación precisa';
    } else if (item.accuracy === '2') {
      item.color = 'yellow';
      item.accuracy_name = 'Ubicación estimada';
    } else if (item.accuracy === 'MANUAL') {
      item.color = 'gray';
      item.accuracy_name= 'Ubicación manual';
    } else {
      item.color = 'red';
      item.accuracy_name = 'Ubicación poco precisa';
    }    
  });
  
  // Obtener todas las coordenadas de filteredData
  const initCoordinates = filteredData.map(item => {
    if (markerPositions[item.punto_id]) {
      return markerPositions[item.punto_id];
    } else {
      return [item.latitud, item.longitud];
    }
  });

  var coordinates = bounds;

  if (initCoordinates.length > 0) {
    coordinates = initCoordinates;
  };

  // Verificar si hay valores NaN o undefined en las coordenadas
  const isValidCoordinates = coordinates.every(coord => coord.every(val => typeof val === 'number' && !isNaN(val)));

  // Estilo CSS para el contenedor del mapa
  const mapContainerStyle = {
    width: '100%', // Ancho deseado en píxeles
    height: 'calc(100vh - 78.3px)', // Altura deseada en píxeles
    borderRadius: '5px',
  };

  const handleMarkerDragStart = (puntoId) => {
    setShowButtons(false); // Oculta los botones al iniciar el arrastre
  };
  
  const handleMarkerDragEnd = (puntoId) => {
    const marker = markerRef.current;
    if (marker != null) {
      const { lat, lng } = marker.getLatLng();
      // Actualiza la posición del marcador correspondiente en markerPositions
      setMarkerPositions(prevMarkerPositions => ({
        ...prevMarkerPositions,
        [puntoId]: [lat, lng]
      }));
    }
    setShowButtons(true); // Muestra los botones al finalizar el arrastre
  };  

  const handleConfirmLocation = (puntoId) => {
    // Lógica para confirmar la ubicación
    setShowButtons(false);
    setSelectedPuntosIds(new Set());
    // Obtener una copia del estado actual de puntosGeograficosToUpdate
    const nuevosPuntos = { ...puntosGeograficosToUpdate };

    // Agregar o actualizar el punto en el objeto puntosGeograficosToUpdate
    nuevosPuntos[puntoId] = {
      punto_id: puntoId,
      latitud: markerPositions[puntoId][0],
      longitud: markerPositions[puntoId][1]
    };

    // Actualizar el estado puntosGeograficosToUpdate con los nuevos puntos
    setPuntosGeograficosToUpdate(nuevosPuntos);
  };

  const handleCancelLocation = (puntoId) => {
    // Lógica para cancelar la ubicación
    setShowButtons(false);
    const newSelectedPuntosIds = new Set();
    setSelectedPuntosIds(newSelectedPuntosIds);
    setMarkerPositions(prevMarkerPositions => ({
      ...prevMarkerPositions,
      [puntoId]: markerInitialPositions[puntoId]
    }));
    const nuevosPuntos = { ...puntosGeograficosToUpdate };

    // Verificar si el punto existe antes de eliminarlo
    if (nuevosPuntos.hasOwnProperty(puntoId)) {
      // Eliminar el punto del objeto nuevosPuntos
      delete nuevosPuntos[puntoId];
    }
    
    // Actualizar el estado puntosGeograficosToUpdate con los nuevos puntos
    setPuntosGeograficosToUpdate(nuevosPuntos);
  };

  const handlePuntoIdClick = (puntoId, clienteId) => {
    let selectedPuntosAux = new Set();
    setShowButtons(false);
    if (selectedPuntosIds.has(puntoId)) {
      setSelectedPuntosIds(selectedPuntosAux);
    } else {
      selectedPuntosAux.add(puntoId)
      setSelectedPuntosIds(selectedPuntosAux);
    };
    setSelectedCustomers(() => {
      const newSelectedCustomers = new Set(selectedCustomers);
      if (newSelectedCustomers.has(clienteId)) {
        newSelectedCustomers.delete(clienteId);
      } else {
        newSelectedCustomers.add(clienteId);
      }
      return newSelectedCustomers;
    });
  };

  const handleConfirmEditPoints = () => {
    setUploading(true);
    const apiUrl = `${config.apiBaseUrl}/ActualizarPuntosGeograficos/`;
    const postData = {
      puntosGeograficosToUpdate: puntosGeograficosToUpdate,
    };

    // Verifica si se ha almacenado un token de acceso en localStorage
    if (!accessToken) {
      console.error('No se encontró un token de acceso en localStorage');
      return;
    }

    // Configura los encabezados para incluir el token de acceso como Bearer
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // Realiza la solicitud Axios con los encabezados configurados
    axios.post(apiUrl, postData, { headers })
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        } else if (response.status === 401) {
          console.error('Token de acceso inválido o expirado');
          navigate(`/login`);
          throw new Error('Token de acceso inválido o expirado');
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      })
      .catch(error => {
        navigate(`/login`);
        console.error('Error al realizar la solicitud:', error);
      });
  };

  const handleRestore = () => {
    setMarkerPositions(markerInitialPositions);
    setSelectedPuntosIds(new Set());
    setPuntosGeograficosToUpdate({});
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleCreated = (e) => {
    const direccionesCliente = direccionesClienteRef.current;
    if (!direccionesCliente || Object.keys(direccionesCliente).length === 0) {
      console.error('direccionesCliente está vacío o indefinido.');
      return;
    }
  
    const { layerType, layer } = e;
    if (layerType === 'polygon') {
      const { _latlngs } = layer;
      const polygonCoordinates = _latlngs[0].map(latlng => [latlng.lat, latlng.lng]);
      setNewPolygon(polygonCoordinates);
      
      const polygon = L.polygon(polygonCoordinates);
  
      const direccionesDentroDelPoligono = Object.values(direccionesCliente).filter(direccion => {
        const latLng = L.latLng(direccion.latitud, direccion.longitud);
        return polygon.getBounds().contains(latLng);
      }).map(direccion => ({
        direccioncliente_id: direccion.direccioncliente_id,
        direccion: direccion.direccion,
        nombre: direccion.nombre,
      }));
      setDireccionesClienteDentroPoligono(direccionesDentroDelPoligono);
      setShowModal(true);
      polygonLayerRef.current = layer;
    }
  };  

  const handleEdit = (e) => {
    const direccionesCliente = direccionesClienteRef.current;
    if (!direccionesCliente || Object.keys(direccionesCliente).length === 0) {
      console.error('direccionesCliente está vacío o indefinido.');
      return;
    }
  
    const editedLayers = e.layers;
  
    editedLayers.eachLayer(layer => {
      if (layer instanceof L.Polygon) {
        // Guardar las coordenadas originales antes de editar
        const originalLatLngs = layer.getLatLngs()[0].map(latlng => [latlng.lat, latlng.lng]);
  
        // Obtener las coordenadas nuevas (editadas)
        const latlngs = layer.getLatLngs()[0];
        const polygonCoordinates = latlngs.map(latlng => [latlng.lat, latlng.lng]);
        setNewPolygon(polygonCoordinates);
  
        const polygon = L.polygon(polygonCoordinates);
  
        const direccionesDentroDelPoligono = Object.values(direccionesCliente).filter(direccion => {
          const latLng = L.latLng(direccion.latitud, direccion.longitud);
          return polygon.getBounds().contains(latLng);
        }).map(direccion => ({
          direccioncliente_id: direccion.direccioncliente_id,
          direccion: direccion.direccion,
          nombre: direccion.nombre,
        }));
  
        setDireccionesClienteDentroPoligono(direccionesDentroDelPoligono);
        setShowModalEdit(true);
        
        // Guarda el polígono editado y las coordenadas originales
        polygonLayerRef.current = layer;
        polygonLayerRef.originalLatLngs = originalLatLngs;
      }
    });
  };

  const handleDelete = (e) => {
    // Lógica para manejar la eliminación de un polígono
    toggleDeleteModal();
  };

  const toggleModal = () => {
    if (newPolygon) {
      // Eliminar el polígono temporal si se cierra el modal
      if (polygonLayerRef.current) {
        polygonLayerRef.current.remove();  // Elimina del mapa
        polygonLayerRef.current = null;    // Limpia la referencia
      }
      
      setNewPolygon(null);  // Limpia el polígono temporal
    }
    
    setShowModal(!showModal);  // Alternar la visibilidad del modal
  };

  // Función para cerrar el modal y restablecer el polígono si no se confirma la edición
  const toggleModalEdit = () => {
    if (newPolygon) {
      // Restaurar el polígono a su estado original si se cierra el modal
      if (polygonLayerRef.current && polygonLayerRef.current.originalLatLngs) {
        polygonLayerRef.current.setLatLngs([polygonLayerRef.current.originalLatLngs]); // Restaurar las coordenadas originales
        polygonLayerRef.current = null;  // Limpiar la referencia
      }
  
      setNewPolygon(null);  // Limpia el estado del polígono editado
    }
    
    setShowModalEdit(!showModalEdit);  // Alternar la visibilidad del modal
  };

  const handleConfirmPolygon = () => {
    toggleModal();
    setUploading(true);
    postCrearPoligonoRestriccionVehiculoCliente(selectedVehicles, newPolygon, navigate);
  };

  const handleConfirmEditPolygon = () => {
    toggleModalEdit();
    setUploading(true);
    putEditarPoligonoRestriccionVehiculoCliente(selectedPolygonId, selectedVehicles, newPolygon, navigate);
  };

  const handlePolygonClick = (poligono) => {
    if (selectedPolygonId === poligono.poligono_id) {
      // Si el polígono ya está seleccionado, lo deseleccionamos
      setSelectedPolygonId(null);
      setSelectedPolygonVehicles([]);
      setSelectedPolygon([]);
    } else {
      // Si no está seleccionado, lo seleccionamos
      setSelectedPolygonId(poligono.poligono_id);
      setSelectedPolygonVehicles(poligono.vehiculos_id);
      setSelectedPolygon(poligono.poligono);
    }
  };

  const handleExitEditMode = () => {
    // Si el polígono ya está seleccionado, lo deseleccionamos
    setSelectedPolygonId(null);
    setSelectedPolygonVehicles([]);
    setSelectedPolygon([]);
  };

  const handleCloseMessage = () => {
    setShowPolygonModeMessage(false);  // Función para cerrar el mensaje manualmente
  };

  const handleExitPolygonMode = () => {
    setIsPolygonMode(false); // Salir del modo polígono
    handleExitEditMode();
  };

  useEffect(() => {
    // Al montar el componente, almacenar las posiciones iniciales de los marcadores
    const initialMarkerPositions = {};
    Object.entries(filteredData).forEach(([key, item]) => {
      initialMarkerPositions[item.punto_id] = [item.latitud, item.longitud];
      if (puntosGeograficosToUpdate[item.punto_id]) {
      }
    });
    setMarkerPositions(initialMarkerPositions);
    setMarkerInitialPositions(initialMarkerPositions);
  }, []); // Solo se ejecuta una vez al montar el componente

  useEffect(() => {
    direccionesClienteRef.current = direccionesCliente;
  }, [direccionesCliente]);

  useEffect(() => {
    // Selecciona todos los clientes cuando direccionesClienteDentroPoligono cambie
    const selectedOptions = direccionesClienteDentroPoligono.map(cli => ({
      id: cli.direccioncliente_id,
      nombre: cli.nombre
    }));
    setSelectedClientes(selectedOptions);
  }, [direccionesClienteDentroPoligono]);

  useEffect(() => {
    if (isPolygonMode) {
      setShowPolygonModeMessage(true);  // Mostrar el mensaje cuando se activa el modo polígono
    } else {
      setShowPolygonModeMessage(false);
    }
  }, [isPolygonMode]);

  return (
    <div>
      <MapContainer
        bounds={bounds}
        zoom={12}
        scrollWheelZoom={true}
        style={mapContainerStyle}
      >
        {showPolygonModeMessage && (
          <div className="polygon-mode-message">
            <div className="message-content">
              <p>
                <span className="polygon-mode-highlight">Modo polígono activado:</span> {" "}
                Utilice el botón en la esquina superior derecha del mapa para dibujar polígonos que restrinjan 
                la asignación de vehículos a las direcciones dentro de las áreas seleccionadas. 
                Puede editar o eliminar polígonos existentes haciendo clic sobre ellos y utilizando los botones correspondientes en la esquina superior derecha.
              </p>
            </div>
            <button className="message-close-btn" onClick={handleCloseMessage}>&times;</button>
          </div>
        )}
        <Map coordinates={coordinates} isValidCoordinates={isValidCoordinates} selectedPolygon={selectedPolygon} />
  
        {/* Mostrar controles de edición y polígonos solo en modo polígono */}
        {isPolygonMode && !selectedPolygonId && (
          <>
          <FeatureGroup>
            <EditControl
              position="topright"
              onCreated={handleCreated}
              draw={{
                rectangle: false,
                circle: false,
                circlemarker: false,
                marker: false,
                polyline: false,
                polygon: true, // Permitir la creación de polígonos
              }}
              edit={{
                remove: false, // No eliminar de entrada
                edit: false, // No editar de entrada
              }}
            />
            {/* Dibujamos todos los polígonos si no hay ninguno seleccionado */}
            {poligonos.map((poligonoItem) => (
              <Polygon
                key={poligonoItem.poligono_id}
                positions={poligonoItem.poligono}
                eventHandlers={{
                  click: () => handlePolygonClick(poligonoItem), // Seleccionamos el polígono
                }}
              />
            ))}
          </FeatureGroup>
          <button
          className="exit-polygon-mode-btn"
          onClick={() => handleExitPolygonMode()}
          style={{ top: '50px'}}
          >
            X
          </button>
          </>
        )}
  
        {/* Si hay un polígono seleccionado, solo mostramos ese polígono */}
        {isPolygonMode && selectedPolygonId && (
          <>
          <FeatureGroup>
            <EditControl
              position="topright"
              onEdited={handleEdit}
              onDeleted={handleDelete}
              draw={{
                rectangle: false,
                circle: false,
                circlemarker: false,
                marker: false,
                polyline: false,
                polygon: false, // No crear más polígonos en modo edición
              }}
              edit={{
                remove: true, // Permitimos eliminar
                edit: true, // Permitimos editar
              }}
            />
            {poligonos
              .filter((poligonoItem) => poligonoItem.poligono_id === selectedPolygonId)
              .map((poligonoItem) => (
                <Polygon
                  key={poligonoItem.poligono_id}
                  positions={poligonoItem.poligono}
                  eventHandlers={{
                    click: () => handlePolygonClick(poligonoItem),
                  }}
                />
              ))}
          </FeatureGroup>
          <button
            className="exit-polygon-mode-btn"
            onClick={() => handleExitPolygonMode()}
            style={{ top: '80px'}}
          >
            X
          </button>
          </>
        )}
  
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        />

        {/* Botón de salida del modo de edición */}
        {selectedPolygonId && (
          <div className="exit-edit-mode-button" onClick={() => handleExitEditMode()}>
            <Button color="danger" className="exit-edit-mode-btn">
              X
            </Button>
          </div>
        )}
  
        {/* Marcadores u otros elementos del mapa */}
        {filteredData.map((item) => (
          <div key={item.direccioncliente_id}>
            <Marker
              position={markerPositions[item.punto_id] || [item.latitud, item.longitud]}
              icon={createCustomIcon(item.color, selectedPuntosIds.has(item.punto_id))}
              draggable={!isPolygonMode && selectedPuntosIds.has(item.punto_id)} // Deshabilitar arrastre en modo polígono
              ref={markerRef}
              eventHandlers={{
                click: !isPolygonMode ? () => handlePuntoIdClick(item.punto_id, item.cliente_id) : null, // Deshabilitar clic en modo polígono
                dragend: !isPolygonMode ? () => handleMarkerDragEnd(item.punto_id) : null, // Deshabilitar evento de drag en modo polígono
              }}
            />
            {!isPolygonMode && showButtons && (
              <div className="map-buttons-container">
                <Button className="btn-icon" color="success" onClick={() => handleConfirmLocation(item.punto_id)}>
                  <i className="tim-icons icon-check-2" />
                </Button>
                <Button className="btn-icon" color="danger" onClick={() => handleCancelLocation(item.punto_id)}>
                  <i className="tim-icons icon-simple-remove" />
                </Button>
              </div>
            )}
          </div>
        ))}

        {!showButtons && !isPolygonMode && (
          <>
            {Object.keys(puntosGeograficosToUpdate).length > 0 && ( // Verificar tamaño del objeto
              <div className="restore-confirm-buttons-container">
                <Button color="success" onClick={() => handleConfirmEditPoints()}>Confirmar Cambios</Button>
                <Button color="warning" onClick={() => handleRestore()}>Restablecer Cambios</Button>
              </div> 
            )}
          </>
        )}
  
        {/* Modales de confirmación */}
        <ConfirmCreateModal
          showModal={showModal}
          toggleModal={toggleModal}
          setSelectedVehicles={setSelectedVehicles}
          handleConfirmPolygon={handleConfirmPolygon}
          vehiculos={vehiculos}
        />
  
        <ConfirmEditModal
          showModal={showModalEdit}
          toggleModal={toggleModalEdit}
          setSelectedVehicles={setSelectedVehicles}
          handleConfirmEditPolygon={handleConfirmEditPolygon}
          vehiculos={vehiculos}
          actualVehicles={selectedPolygonVehicles}
        />
  
        <ConfirmDeleteModal
          isOpen={isDeleteModalOpen}
          toggleModal={toggleDeleteModal}
          poligonoId={selectedPolygonId}
          setUploading={setUploading}
          deletePoligonoRestriccionVehiculoCliente={deletePoligonoRestriccionVehiculoCliente}
          navigate={navigate}
        />
      </MapContainer>
    </div>
  );
}

export default LeafletMapCustomers;